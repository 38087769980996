module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de","en"],"defaultLanguage":"de","i18nextOptions":{"fallbackLng":"de","supportedLngs":["de","en"],"defaultNS":"index","nsSeparator":":","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
