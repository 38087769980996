import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import Cookies from "js-cookie";



export const reactPlugin = new ReactPlugin();


export const cookiesInitiallyEnabledAI = () => {
    const gtag_enabled = Cookies.get("ai_enabled");
    return !gtag_enabled ? undefined : /^true$/i.test(gtag_enabled);
};

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "bcff59f8-1cad-4b93-9dfc-23543c962808",
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        cookieCfg: {
            enabled: cookiesInitiallyEnabledAI() ?? false
        },
    },
});
