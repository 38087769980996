import { appInsights } from "./applicationinsights";
//@ts-ignore
import { WOW } from "wowjs/dist/wow.js";
import { initGtag } from "./googleanalytics";

export const onRouteUpdate = () => {
    new WOW().init();
};
export const onInitialClientRender = () => {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    initGtag();
};
