
import Cookies from 'js-cookie';
const googletagmanagerId = "G-MXR3V4NCLT";

declare global {
    interface Window {
        gtag(prop: string, val: string | Date, options?: Record<string, string | number>): void;
        dataLayer: unknown[];
    }
}

export const cookiesInitiallyEnabledGTag = () => {
    const gtag_enabled = Cookies.get("gtag_enabled");
    return !gtag_enabled ? undefined : /^true$/i.test(gtag_enabled);
};

let scriptTag: HTMLScriptElement | undefined;

export const initGtag = () => {
    if (scriptTag) {
        return;
    }
    scriptTag = document.createElement("script");
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${googletagmanagerId}`;
    scriptTag.async = true;
    scriptTag.onload = () => {
        // eslint-disable-next-line prefer-rest-params
        window.gtag = function () {
            if (!window.dataLayer) window.dataLayer = [];
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 500
        });
        window.gtag('config', googletagmanagerId);
        if (cookiesInitiallyEnabledGTag) {
            updateConsent(cookiesInitiallyEnabledGTag() ?? false);
        }
    };
    document.head.appendChild(scriptTag);
};

export const updateConsent = (consent: boolean) => {
    window.gtag('consent', 'update', {
        'ad_storage': consent ? 'granted' : 'denied',
        'analytics_storage': consent ? 'granted' : 'denied'
    });
};
